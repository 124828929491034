import React from 'react';
import { string, bool } from 'prop-types';

import ActivateCard from 'components/cards/activate';
import UpdateCard from 'components/cards/update';
import DeleteCard from 'components/cards/delete';
import SkeletonCard from 'components/cards/skeleton';

import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const ActionCompleteView = ({ passId, title, loading = true }) => {
  const styles = useStyles();

  return (
        <div className={styles.menucontent}>
        <Grid spacing={2}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            className={styles.grid}
          >
          <Grid item md={4} sm={6} xs={12}>
              {loading ? <SkeletonCard/> : <ActivateCard passId={passId} title={title}/>}
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
              {loading ? <SkeletonCard/> : <DeleteCard passId={passId} title={title}/>}
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
              {loading ? <SkeletonCard/> : <UpdateCard passId={passId} title={title}/>}
          </Grid>
        </Grid>
        </div>
  );
};

ActionCompleteView.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
  loading: bool,
};

export default ActionCompleteView;
