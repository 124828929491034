import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { UPDATE_ROUTE } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/pro-regular-svg-icons';
import { faWrench as solid } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const UpdateCard = ({ passId, title }) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${UPDATE_ROUTE}?passId=${passId}`)}
          title="Update"
          description="Change loyalty card information, update image and colors"
          buttonMessage="Update"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faWrench} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={solid} />
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

UpdateCard.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default UpdateCard;
