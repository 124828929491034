import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { ACTIVATE_ROUTE } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const ActivateCard = ({ passId, title }) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${ACTIVATE_ROUTE}?passId=${passId}`)}
          title="Activate"
          description="Enable your loyalty card"
          buttonMessage="Activate"
          imageChild={
            <div className={styles.iconContainer}>
              <span>
                <FontAwesomeIcon icon={faPlay} size="8x" className={styles.largeIcon}/>
              </span>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faPlay} />
            </span>
          }
          />;
};

ActivateCard.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default ActivateCard;
